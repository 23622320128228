import React, { useRef } from "react";
import Image, { ImageProps } from "next/legacy/image";
import CombinedLink from "../CombinedLink";
import { useSession } from "next-auth/react";
import clsx from "clsx"
import {appStoreUrl ,googlePlayUrl } from "@/lib/constants/appDownload"
import { QRCodeSVG } from "qrcode.react";
import { isAndroid, isBrowser, isMobile,isIOS } from "react-device-detect";
import { motion, useScroll, useTransform, useViewportScroll, Variants } from "framer-motion";
import IntroHomeIcon from "@/assets/appIntro/intro_home.png";
import GooglePlayIcon from "@/assets/appIntro/google_play.png";
import ShareIAppStoreIcon from "@/assets/appIntro/app_store.png";
import { ReactComponent as YellowBg } from "@/assets/appIntro/yellow_bg.svg";

import Intro1_1_Icon from "@/assets/appIntro/intro1/intro1_1.png";
import Intro1_2_Icon from "@/assets/appIntro/intro1/intro1_2.png";
import Intro1_3_Icon from "@/assets/appIntro/intro1/intro1_3.png";
import Intro1_4_Icon from "@/assets/appIntro/intro1/intro1_4.png";
import Intro1_5_Icon from "@/assets/appIntro/intro1/intro1_5.png";
import Intro1_6_Icon from "@/assets/appIntro/intro1/intro1_6.png";

import Intro2_1_Icon from "@/assets/appIntro/intro2/intro2_1.png";
import Intro2_2_Icon from "@/assets/appIntro/intro2/intro2_2.png";
import Intro2_3_Icon from "@/assets/appIntro/intro2/intro2_3.png";
import Intro2_4_Icon from "@/assets/appIntro/intro2/intro2_4.png";
import Intro2_5_Icon from "@/assets/appIntro/intro2/intro2_5.png";
import Intro2_6_Icon from "@/assets/appIntro/intro2/intro2_6.png";
import Intro2_7_Icon from "@/assets/appIntro/intro2/intro2_7.png";
import Intro2_8_Icon from "@/assets/appIntro/intro2/intro2_8.png";

import Intro3_1_Icon from "@/assets/appIntro/intro3/intro3_1.png";
import Intro3_2_Icon from "@/assets/appIntro/intro3/intro3_2.png";
import Intro3_3_Icon from "@/assets/appIntro/intro3/intro3_3.png";
import Intro3_4_Icon from "@/assets/appIntro/intro3/intro3_4.png";
import Intro3_5_Icon from "@/assets/appIntro/intro3/intro3_5.png";
import Intro3_6_Icon from "@/assets/appIntro/intro3/intro3_6.png";
import Intro3_7_Icon from "@/assets/appIntro/intro3/intro3_7.png";

import Intro4_1_Icon from "@/assets/appIntro/intro4/intro4_1.png";
import Intro4_2_Icon from "@/assets/appIntro/intro4/intro4_2.png";
import Intro4_3_Icon from "@/assets/appIntro/intro4/intro4_3.png";
import Intro4_4_Icon from "@/assets/appIntro/intro4/intro4_4.png";
import Intro4_5_Icon from "@/assets/appIntro/intro4/intro4_5.png";

// Variants
const motions = {
  sectionOnScreen: {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1,
      },
    },
  },
  rightTitle: {
    offscreen: {
      x: 300,
      opacity: 0,
      y: -100,
    },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1,
      },
    },
  },

  leftTitle: {
    offscreen: {
      x: -300,
      opacity: 0,
      y: 100,
    },
    onscreen: {
      x: 0,
      y: 0,
      opacity: 1,
      transition: {
        delay: 0,
        duration: 1,
        ease: "backIn",
      },
    },
  },
  fingerTap: {
    y: -10,
    x: -10,
  },
  flashPoint: {
    animate: {
      scale: [1, 0.5, 1],
      transition: {
        duration: 2,
        ease: "linear",
        times: [0, 0.5, 1],
        repeat: Infinity,
      },
    },
  },
};

export const AppIntro = () => {
  const { data } = useSession();
  const step1HandRef = useRef(null)
  const step2HandRef = useRef(null)
  const { scrollYProgress:step1HandProgress } = useScroll({
    target: step1HandRef,
    offset: ["end end", "start start"]
  })
  const { scrollYProgress:step2HandProgress } = useScroll({
    target: step2HandRef,
    offset: ["end end", "start start"]
  })
  const step1HandTranslation = useTransform(step1HandProgress, [0, 1], [-100, 100]);
  const step2HandTranslation = useTransform(step2HandProgress, [0, 1], [0, -200]);
  const getUrlWithUtm = (urlStr:string)=>{
    const url = new URL(urlStr);
    url.searchParams.set("utm_source", "web");
    url.searchParams.set("utm_medium", "ccapppage");
    return url.toString();
  }
  return (
    <div className="   overflow-x-hidden  [&_img]:pointer-events-none    ">
      <div className="flex  flex-col-reverse lg:flex-row w-full  px-10  mx-auto lg:mb-24">
        <div className="flex-1 flex flex-col gap-3   items-center lg:items-start align-start max-w-screen-md  pl-2">
          <div className=" text-gray-500 dark:text-white  font-black   text-3xl lg:text-7xl leading-none mb-3 lg:mb-5  ">
            Channel C HK App
          </div>
          <div className=" text-gray-500 dark:text-white text-3xl lg:text-6xl font-thin !leading-tight">
            掌握香港即時新聞資訊
          </div>
          <div className="  max-w-lg  mt-4 lg:mt-16 z-10">
            <div className=" flex flex-row    items-center  justify-between  mb-4  gap-2 lg:gap-6 overflow-visible select-none">
              <CombinedLink href={getUrlWithUtm(appStoreUrl)} target={"_blank"}>
                <Image
                  priority={true}
                  src={ShareIAppStoreIcon}
                  className="  cursor-pointer hover:opacity-90 "/>
              </CombinedLink>
              <CombinedLink href={getUrlWithUtm(googlePlayUrl)} target={"_blank"}>
                <Image
                  priority={true}
                  src={GooglePlayIcon}
                  className=" cursor-pointer hover:opacity-90 "
                /></CombinedLink>
            </div>
            <div className="leading-none mb-5 flex flex-row items-center text-center  align-bottom  text-gray-500  text-lg lg:text-4xl">
              <hr className=" mx-auto w-[40%]  bg-gray-500 " />
              或
              <hr className=" mx-auto w-[40%]  bg-gray-500 " />
            </div>
            <div className="flex  flex-col lg:flex-row items-center gap-2 lg:gap-6  text-lg lg:text-2xl z-10 dark:text-white ">
              <div className="bg-white p-2 z-10">
                <QRCodeSVG value={`https://channelchk.com/app-download`}  bgColor="white" includeMargin={false}  size={120} />
              </div>
            掃描 QR Code
            </div>
          </div>
        </div>
        <div className=" flex-1">
          <div className={" z-10  flex flex-row justify-center  lg:px-[10%]  "}>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                ease: "backIn",
                delay: 0.5,
                duration: 1,
              }}
              className="max-w-[28rem] z-10 select-none"
              viewport={{ once: true }}
            >
              <Image
                priority={true}
                src={IntroHomeIcon}
                className={"z-10 w-full h-full"}
              />
            </motion.div>
          </div>
        </div>
      </div>
      <div className={clsx(" select-none hidden lg:block",isMobile&&" hidden")}>
        {/* 第二行 */}
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={motions.sectionOnScreen}
          transition={{ duration: 1 }}
          className=" relative flex flex-row overflow-visible mb-10 pt-16"
          viewport={{ once: true }}
        >
          <YellowBg className="w-full  z-0 absolute -top-28 2xl:-top-60  right-0 left-0 " />
          <div className=" relative  flex-1  flex flex-row justify-center p-0 pb-0 ">
            <motion.div
              viewport={{ once: true }}
              initial={{ x: -300 }}
              whileInView="onscreen"
              variants={{
                onscreen: {
                  x: 0,
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
              className=" relative  max-w-[340px] ">
              <motion.div>
                <Image
                  src={Intro1_1_Icon}
                  className={"z-10 w-full h-full "}
                  objectFit={"contain"}
                />
              </motion.div>
              <div className=" absolute top-14 -left-20 -right-20 z-10 ">
                <Image src={Intro1_2_Icon} className={"w-full "} />
                <div className="absolute  top-[45%]  left-[20%] z-10">
                  <Image src={Intro1_3_Icon}  className=""/>
                </div>
                <motion.div
                  ref={step1HandRef}
                  style={{translateX: step1HandTranslation}}
                  // animate={{
                  //   x: [-100,100],
                  // }}
                  // transition={{
                  //   duration: 1.5,
                  //   ease: "linear",
                  //   times: [0, 1],
                  //   repeat: Infinity,
                  //   repeatDelay: 1,
                  // }}
                  // viewport={{ once: true, amount: 0.8 }}
                  className=" absolute top-24  left-[40%]  z-10"
                >
                  <Image src={Intro1_4_Icon} className={" w-full h-full "} />
                </motion.div>
              </div>
            </motion.div>
          </div>

          <div className="flex-1 relative  pt-20 pr-10">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={motions.rightTitle}
              className="max-w-[45rem] relative"
              viewport={{ once: true }}
            >
              <Image src={Intro1_5_Icon} className={"z-10 w-full h-full "} />
              <div className=" absolute right-0 left-0  -bottom-[10%]">
                <Image src={Intro1_6_Icon} className={" w-full h-full "} />
              </div>
            </motion.div>
          </div>
        </motion.div>

        {/* 第三行 */}
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={motions.sectionOnScreen}
          transition={{ duration: 1 }}
          className=" flex flex-row  relative mb-20 "
          viewport={{ once: true }}
        >
          <div className="flex-1  relative  flex flex-col items-center ">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={motions.leftTitle}
              className=" relative max-w-xl pl-[5%] "
              viewport={{ once: true }}
            >
              <Image src={Intro2_1_Icon} className={"z-10 w-full h-full "} />
              <div className="  absolute  right-[30%]  -left-[50%]  -bottom-[30%] ">
                <Image src={Intro2_2_Icon} className={" w-full h-full "} />
              </div>
            </motion.div>
            <div className=" relative   pr-[30%]">
              <motion.div
                style={{translateX: step2HandTranslation}}
                initial={{ opacity: 0, y: 100 }}
                whileInView={{opacity: 1, y: 0 }}
                transition={{
                  delay: 0,
                  duration: 1,
                  ease: "linear",
                }}
                className="relative  max-w-[40rem] z-10"
                viewport={{ once: true }}
              >
                <Image src={Intro2_3_Icon} className={" w-full h-full "} />
              </motion.div>
              <motion.div
                style={{translateX: step2HandTranslation}}
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{
                  delay: 1,
                  duration: 2,
                  ease: "linear",
                }}
                className=" absolute top-[15%] left-[40%]  z-10  "
                viewport={{ once: true }}
              >
                <Image src={Intro2_4_Icon} className={" w-full h-full "} />
              </motion.div>
            </div>
          </div>
          <div className=" relative flex-1 flex flex-row  justify-center z-10">
            <div className=" relative max-w-[40rem]">
              <Image src={Intro2_5_Icon} className={" w-full h-full "} />
              <div className=" absolute bottom-[40%]  left-[10%] max-w-2xl">
                <div className=" relative">
                  <Image src={Intro2_6_Icon} className={" w-full h-full "} />
                  <div className=" absolute top-[10%] xl:top-[15%]  left-[55%] ">
                    <motion.div animate={"animate"} variants={motions.flashPoint} >
                      <Image src={Intro2_7_Icon} className={" w-full h-full "} />
                    </motion.div>
                    <motion.div
                      whileHover={motions.fingerTap}
                      className=" absolute  w-full xl:w-80  top-[50%] left-[45%]  "
                    >
                      <Image src={Intro2_8_Icon} className={" w-full h-full"} />
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* 第四行 */}
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          variants={motions.sectionOnScreen}
          transition={{ duration: 1 }}
          className="  flex flex-row relative  -mt-60 2xl:-mt-96 p-10"
          viewport={{ once: true }}
        >
          <YellowBg className="w-full  h-full lg:h-auto z-0 absolute  -top-[10%]  2xl:-top-[20%]  bottom-0 right-0 left-0 " />

          <div className="flex-1  relative flex flex-row justify-center">
            <div className=" relative max-w-[340px] overflow-visible">
              <Image src={Intro3_1_Icon} className={" w-full h-full "} />
              <div className=" absolute   top-[6%]   w-[33rem] -right-[50%]  overflow-visible">
                <Image src={Intro3_2_Icon} className={" w-full h-full "} />
                <div className="absolute top-[15%]  left-[25%]   overflow-visible">
                  <motion.div
                    animate={"animate"}
                    variants={motions.flashPoint}
                    className=" relative w-44 h-44  overflow-visible"
                  >
                    <Image src={Intro3_3_Icon} className={" w-full h-full "} />
                  </motion.div>
                  <motion.div
                    whileHover={motions.fingerTap}
                    className=" absolute  w-64 xl:w-80 top-[40%]  left-[40%]  "
                  >
                    <Image src={Intro2_8_Icon} className={" w-full h-full "} />
                  </motion.div>
                </div>
              </div>
              <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{
                  duration: 0.5,
                  ease: "linear",
                }}
                className=" absolute max-w-[14rem] max-h-[14rem] w-[50%] xl:w-full -bottom-[10%]  -left-[0%] xl:-left-[20%] "
                viewport={{ once: true }}
              >
                <Image src={Intro3_5_Icon} className={" w-full h-full z-10 "} />
              </motion.div>
            </div>
          </div>
          <div className=" flex-1 flex flex-row justify-center pt-32 pl-10">
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              variants={motions.rightTitle}
              className="  h-fit  relative max-w-[40rem] overflow-visible "
              viewport={{ once: true }}
            >
              <Image src={Intro3_6_Icon} className={"z-10 w-full h-full "} />
              <div className=" absolute left-10 -right-[20%] -bottom-[20%] ">
                <Image src={Intro3_7_Icon} className={"w-full h-full "} />
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
      {/* 第五行 */}

      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={motions.sectionOnScreen}
        transition={{ duration: 1 }}
        className=" flex  flex-col lg:flex-row relative px-10 select-none pb-10"
        viewport={{ once: true }}
      >
        <div className={clsx("mt-20 lg:hidden",)}>
          {/* <YellowBg className=" h-full w-full  z-0 absolute  -top-[5%]  2xl:-top-[20%]   right-0 left-0 " /> */}
          <div className=" bg-primary-500 w-full absolute top-[10%] bottom-[15%]    right-0 left-0" />
          <YellowBg className=" h-fit w-full  z-0 absolute  -top-[5%]     right-0 left-0 " />
          <YellowBg className=" h-fit w-full  z-0 absolute bottom-0   right-0 left-0 " />
        </div>
        <div className="flex-1 relative  flex flex-col  items-center justify-center  gap-[10%]  ">
          <motion.div
            initial={"offscreen"}
            whileInView={"onscreen"}
            variants={motions.leftTitle}
            className=" relative max-w-2xl"
            viewport={{ once: true }}
          >
            <Image src={Intro4_1_Icon} className={" w-full h-full z-10 "} />
            <div className=" absolute  -right-[0%] 2xl:-right-[20%] -left-[20%] 2xl:-left-[35%] -bottom-[20%] 2xl:-bottom-[25%]">
              <Image src={Intro4_2_Icon} className={" w-full h-full "} />
            </div>
          </motion.div>
          {!data?.user?.id && (
            <motion.div
              whileTap={{
                scale: 0.9,
              }}
              className=" max-w-[26rem] cursor-pointer "
              viewport={{ once: true }}
            >
              <CombinedLink href="/auth/login">
                <Image src={Intro4_3_Icon} className={" w-full h-full z-10 "} />
              </CombinedLink>
            </motion.div>
          )}
        </div>
        <div className=" flex-1 relative flex flex-row  ">
          <motion.div
            initial={{ y: -100, zIndex: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 2,
              duration: 1,
            }}
            className="relative  w-full  max-w-[30rem] -right-[10%] top-0 "
            viewport={{ once: true }}
          >
            <Image
              src={Intro4_4_Icon}
              className={" w-full h-full z-10 relative -left-11 "}
            />
          </motion.div>
          <motion.div
            initial={{ y: -100, zIndex: 0, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              delay: 3,
              duration: 1,
            }}
            viewport={{ once: true }}
            className="  relative w-full -left-[5%] bottom-4 lg:bottom-8  max-w-[30rem]">
            <Image src={Intro4_5_Icon} className={" w-full h-full "} />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};
