import type { GetStaticProps, NextPage } from 'next'
import React from 'react'
import Seo from '../components/Seo'
import { ComponentSharedSeo } from '../lib/remoteGraphQL'
import { enrichPropsWithGlobalInfo } from '../lib/static-props-enricher/global'
import { MyAppProps } from './_app'
import {AppIntro}  from "@/components/appIntro";
import { enableApp } from '@/lib/constants/appDownload'
const AppIntroPage: NextPage<MyAppProps> = (props: MyAppProps) => {


  const seo = {
    metaTitle: `Channel C HK App`,
    metaDescription: `《Channel C HK》App 帶你睇香港每日即時要聞和熱話、直播新聞，逢星期二專題踢爆社會不公、報道奇人奇事，內容由港聞、娛樂、天氣至兩岸國際，用最地道方式了解香港發生的大小事。`
  } as ComponentSharedSeo;

  return (
    <>
      <Seo seo={seo} />
      <AppIntro />
    </>
  )
}

export const getStaticProps: GetStaticProps<any> = async (context: any) => {
  if(!enableApp)
    return {
      notFound:true,
      revalidate: 60
    }
  return {
    props: await enrichPropsWithGlobalInfo({}),
    revalidate: 60
  }
};

export default AppIntroPage;